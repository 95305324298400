import Head from 'next/head';
import { FC } from 'react';
import Script from 'next/script';

export interface HeaderProps {
  venueLogo?: string;
  metaOGUrl?: string;
  metaOGImg: string;
  metaOGVideo?: string;
  metaOGTitle: string;
  metaOGDescription: string;
  metaTwitterImg: string;
  metaTwitterTitle: string;
  metaTwitterDescription: string;
  metaTwitterSiteId: string;
  title?: string;
  venueId: string;
}

export const Header: FC<HeaderProps> = ({
  children,
  venueLogo,
  metaOGTitle,
  metaOGDescription,
  metaOGUrl,
  metaOGImg,
  metaOGVideo,
  metaTwitterImg,
  metaTwitterTitle,
  metaTwitterDescription,
  metaTwitterSiteId,
  title,
  venueId,
}) => {
  const favicon = venueLogo || '/favicon.ico';

  return (
    <Head>
      {/* Primary Meta Tags */}
      <script src="https://www.googletagmanager.com/gtag/js?id=G-P5BPYGJNER" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-P5BPYGJNER', {
      venue_id: "${venueId}"
    });
  `,
        }}
      />
      <title>{title || metaOGTitle}</title>
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      <meta name="title" content={metaOGTitle} />
      <meta name="description" content={metaOGDescription} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaOGUrl} />
      <meta property="og:title" content={metaOGTitle} />
      <meta property="og:description" content={metaOGDescription} />
      <meta
        property="og:image"
        content={metaOGVideo ? metaOGVideo.split('.').slice(0, -1).join('.') + '.jpg' : metaOGImg}
      />
      {metaOGVideo && <meta property="og:video" content={metaOGVideo} />}
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site:id" content={metaTwitterSiteId} />
      <meta property="twitter:title" content={metaTwitterTitle} />
      <meta property="twitter:description" content={metaTwitterDescription} />
      <meta property="twitter:image" content={metaTwitterImg} />
      {/* Favicon */}
      <link rel="icon" href={favicon} />

      {children}
    </Head>
  );
};
