import { createCustomTheme, theme } from '../../theme';
import { VenueProperties } from '../../api/models/venue';

export const getAlteredTheme = (venueAttributes: {properties: Partial<VenueProperties>}) => {
  if (!venueAttributes) {
    return theme;
  }

  const primaryColor = venueAttributes.properties?.['webapp.config']?.['primary-color'];
  const secondaryColor = venueAttributes.properties?.['webapp.config']?.['secondary-color'];
  const textColor = venueAttributes.properties?.['webapp.config']?.['text-color'];

  return createCustomTheme({
    primaryColor,
    secondaryColor,
    textColor,
  });
};
