export const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const AuthEndpoints = {
  GetUser: `${BASE_URL}/api/v1/users/:id`,
  RegisterUser: `${BASE_URL}/api/v1/users/register`,
  LoginUser: `${BASE_URL}/api/v1/auth`,
  UpdateUser: `${BASE_URL}/api/v1/users/:id`,
  RefreshToken: `${BASE_URL}/api/v1/auth/refresh`,
};

export const VenueEndpoints = {
  Venue: `${BASE_URL}/api/v1/accounts/:id`,
  GetVenueProperties: `${BASE_URL}/api/v1/accounts/:id/properties?prefix=webapp`,
  GetOrganizationById: `${BASE_URL}/api/v1/organizations/:id`,
};

export const StoryEndpoints = {
  StoryToday: `${BASE_URL}/api/v1/topgolf/videos/today`,
  TrackShareClicks: `${BASE_URL}/api/v1/topgolf/videos/:id/share`,
  GetStoryById: `${BASE_URL}/api/v1/topgolf/videos/:id`,
  TrackStoryViews: `${BASE_URL}/api/v1/topgolf/videos/:id/view`,
  TrackStoryCtaClicks: `${BASE_URL}/api/v1/topgolf/videos/:id/cta-click`,
};

export const getApiUrlForId = (apiUrl: string, id: string): string => {
  return apiUrl.replace(/:id/, id);
};

export const UploadEndpoints = {
  CreateUploadUrl: `${BASE_URL}/api/v1/stories/create-upload-signed-url`,
  CreateAvatarUploadUrl: `${BASE_URL}/api/v1/users/create-avatar-upload-signed-url`,
  CreateThumbnailUploadUrl: `${BASE_URL}/api/v1/stories/create-thumbnail-signed-url`,
};

