export const defaultVenueSubDomain = 'stagetg';

export enum ErrorMessages {
    ExpiredJwtException = 'ExpiredJwtException',
    Unauthorized = 'Unauthorized',
    NotFound = 'Not Found',
  }


export interface DefaultPaginationValues {
  page: number;
  totalItems: number;
  totalPages: number;
  size: number;
  sort: string;
  sortByLastCreated: string;
  sortByFirstCreated: string;
}

export const defaultPagination = {
  page: 0,
  totalItems: 0,
  totalPages: 0,
  size: 10,
  sort: '',
  sortByLastCreated: 'createdAt,desc',
  sortByFirstCreated: 'createdAt,asc',
} as DefaultPaginationValues;