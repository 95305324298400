export enum AppRoutes {
  share = '/share',
  home = '/',
  StoryLink = '/s',
}

export const getApiUrlWithIdQueryParam = (apiUrl: string, id?: string): string => {
  return apiUrl + `?id=${id}`;
};

export const cloudinaryUploadLink = 'https://api.cloudinary.com/v1_1/socialvenu/upload/';
export const maxSizeUploadedUserFile = 400000000;
export const twitterShareLink = 'https://twitter.com/intent/tweet?url=';
export const facebookShareLink = 'https://www.facebook.com/sharer/sharer.php?u=';
export const linkedinShareLink = 'http://www.linkedin.com/shareArticle?mini=true&url=';

export const urlMaskRegex = new RegExp(
  `((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`,
);
export const simpleUrlMaskRegex = new RegExp(`^(http|https)://`);

export interface DefaultPaginationValues {
  page: number;
  totalItems: number;
  totalPages: number;
  size: number;
  sort: string;
  sortByLastCreated: string;
  sortByFirstCreated: string;
}

export const defaultPageSizing = {
  tabletPageWidth: '100%',
  desktopPageWidth: '1128px',
  desktopContentWidth: '672px',
  mobilePageWidth: '100%',
};

export const defaultPagination = {
  page: 0,
  totalItems: 0,
  totalPages: 0,
  size: 10,
  sort: '',
  sortByLastCreated: 'createdAt,desc',
  sortByFirstCreated: 'createdAt,asc',
} as DefaultPaginationValues;

export const isIosChromeMobileBrowser = () => {
  return /CriOS/i.test(window.navigator.userAgent) && /iphone|ipod|ipad/i.test(window.navigator.userAgent);
};

export enum EventTypes {
  ButtonClick = 'Button Click',
  LinkClick = 'Link Click',
  VideoCreated = 'video created',
  FormSubmit = 'form submit',
  CarouselSwipe = 'Couarousel Swipe',
}

export enum SocialNetworks {
  Linkedin = 'Linkedin',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
}

export enum Messages {
  Sms = 'sms',
  Email = 'email',
}
export enum EventActions {
  getStarted = 'get_started_click',
  acceptTos = 'accept_tos',
  selectVideo = 'select_video',
  selectShotTime = 'select_shot_time',
  incentiveCTA = 'incentive_cta',
  scrollVideo = 'scroll_videos',
  shareClick = 'share_click',
  Facebook = 'share_fb_click',
  Twitter = 'share_tw_click',
  Message = 'share_sms_click',
  Email = 'share_email_click',
  Link = 'share_link_click',
  Download = 'share_download_click',
  LinkedIn = 'share_linkedin_click',
  copyLink = 'copy_link',
  ctaClick = 'cta_click',
  videoPlay = 'video_play',
  videoPause = 'video_pause',
  sharedVideoView = 'shared_video_view',
  firstTimeVisit = 'first_time_visit',
}

export enum LayoutMode {
  Desktop = 1,
  Tablet,
  Mobile,
}

export enum VideoFlowErrorMessages {
  ctaText = 'Unfortunately, we cannot save the button without a text. \n Please add a CTA Text.',
  metaTitle = 'Unfortunately, we cannot save empty meta title. \n Please add a Meta Title.',
  buttonUrl = 'Unfortunately, we cannot save the button without a link. \n Please add a CTA Url.',
}

export enum ButtonsTypes {
  Message = 'Message',
  Email = 'Email',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Link = 'Link',
  Linkedin = 'LinkedIn',
  Download = 'Download',
}
