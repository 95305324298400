export interface eventProps {
  action: string;
  venueId?: string;
  videoId?: string;
}
export interface pageViewProps {
  url: string;
  venueId?: string;
  videoId?: string;
}
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
// SEGMENT EVENT TRACK
const trackSegmentEvent: (arg0: eventProps) => void = ({ action, venueId, videoId }) => {
  window.analytics.track(action, { venueId, videoId });
};
// SEGMENT TRACK PAGE VIEW
const trackSegmentPageView: (arg0: pageViewProps) => void = ({ url, venueId, videoId }) => {
  window.analytics.page(url, { venueId, videoId });
};
// SEGMENT TRACK LINK OPTIONAL??
export const segmentTrackLink = (id: string, eventName: string, venueId: string) => {
  var link = document.getElementById(id);

  window.analytics.trackLink(link, eventName, { venueId });
};

// GA4 EVENT TRACK
const trackGAEvent: (arg0: eventProps) => void = ({ action, videoId }) => {
  window.gtag(
    'event',
    action,
    videoId
      ? {
          video_id: videoId,
        }
      : {},
  );
};
// GLOBAL TRACK PAGE VIEW
export const trackPageView: (arg0: pageViewProps) => void = ({ url, venueId, videoId }) => {
  // trackGAPageView({url, venueId, videoId})
  trackSegmentPageView({ url, venueId, videoId });
};
// GLOBAL EVENT TRACK
export const trackEvent: (arg0: eventProps) => void = ({ action, venueId, videoId }) => {
  trackSegmentEvent({ action, venueId, videoId });
  trackGAEvent({ action, videoId });
};
